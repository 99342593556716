import React from 'react';
import { Col, Row } from 'antd';
import PaymentForm from "./paymnet_from"
import heuristSrc from './assets/heurist.jpeg';

const boxStyle = {
    width: '100%',
    height: 220,
    // borderRadius: 6,
    // border: '1px solid #40a9ff',
};

const Main_view = () => {
    return (
        <Row gutter={24} align="middle" style={{ padding: '20px', height: '100vh' }}>
            <Col span={7}> {/* Adjust span as needed */}
                <div style={{ padding: '20px' }}>
                    <img src={heuristSrc} alt="Logo"
                        style=
                        {{
                            // height: '10',
                            width: "6vh",
                            // margin: 10
                            borderRadius: 12
                        }}
                    />

                    <h2>Deploy Your Heurist Miner</h2>
                    <br />
                    <p
                        style={
                            {
                                color: "#9F9F9F"
                            }
                        }>
                        Connect to your Metamask Wallet on Arbitrum, Set the miner details and Deploy your Heurist Miner with Nirmaan
                    </p>
                </div>
            </Col>
            <Col span={2}></Col>
            <Col span={15} >
                {/* <Flex style={boxStyle} justify="space-around" align='center'> */}
                <PaymentForm />
                {/* </Flex> */}

            </Col>
        </Row>
    );
};
export default Main_view;