import React from 'react';
import { Layout, Divider } from 'antd';
import Main_view from './main_view';
import logoSrc from './assets/logo.png';
import { TwitterOutlined, MediumOutlined, AreaChartOutlined } from '@ant-design/icons';
import './CSS/app.css';

const { Sider } = Layout;

const background_color = '#1C1B1E'

const App = () => {
  return (
    <Layout style={{ minHeight: '100vh', }}>
      <Sider
        style={{
          background: '#1C1B1E', // this color should match the one in your design
          color: '#fff',
          borderRight: '#fff'
        }}
        theme="dark"
        breakpoint="lg"
        collapsedWidth="0"
        width="250"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="logo"
          style={{
            height: '22px',
            margin: '6px',
            marginLeft: '6px',
            padding: 10
            // background: 'rgba(255, 255, 255, 0.3)', // Replace with your logo
          }}
        />
        <img src={logoSrc} alt="Logo"
        style={{
          marginLeft: '20px', // Move the logo slightly to the left
          marginTop: '10px', // Adjust vertical positioning if needed
          marginBottom: '10px',
          maxHeight: '45px', // Set a maximum height to limit the logo size
          objectFit: 'contain', // Maintain aspect ratio and fit within the container
        }}
       />
       
    <div style={{ marginLeft: '20px', marginTop: '40px' }}>
        <a
        href="https://www.heurist.ai/mining"
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: 'block', color: 'white', textDecoration: 'none', marginBottom: '15px' }} // Adjust marginBottom as needed
        >
          Track Miner <AreaChartOutlined style={{ marginRight: '8px', marginLeft:'15px' }} />
        </a>
        
        <a
        href="https://mirror.xyz/0xC36A87666c505Fe695fc097d238725ff4d34877D"
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: 'block', color: 'white', textDecoration: 'none', marginBottom: '15px' }} // Adjust marginBottom as needed
        >
           Our Mirror   <MediumOutlined style={{ marginRight: '8px', marginLeft:'23px' }} /> 
        </a>

        <a
        href="https://twitter.com/nirmaanai"
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: 'block', color: 'white', textDecoration: 'none', marginBottom: '15px' }} // Adjust marginBottom as needed
        >
          Our Twitter   <TwitterOutlined style={{ marginRight: '8px', marginLeft:'19px' }} /> 
          </a>
    </div>


      </Sider>
      <Divider type="vertical" style={{ height: '100vh', margin: '0', backgroundColor: '#B866F6' }} />

      <Layout
        style={{ background: '#1C1B1E' }}
      > {/* Match the background with the design */}
        <Main_view />
      </Layout>
    </Layout >
  );
};

export default App;
