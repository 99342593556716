import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Radio, Space, Select} from 'antd';
import { notification } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import './CSS/PaymentForm.css';
import { ethers } from 'ethers';
import iconSrc from './assets/icon.png';

const { Option } = Select;

const arb_usdc_address = "0xaf88d065e77c8cC2239327C5EDb3A432268e5831"
const nirmaan_wallet_address = "0x22E0225540ccf80aB3C4F029F3dE75dB785754A3"

const duration_options = [
    { 'value': "0 Month", 'name': "Set your duration" },
    { 'value': "1 Month", 'name': "1 Month ($1)" },
    { 'value': "2 Months", 'name': "2 Months ($7)" },
    { 'value': "3 Months", 'name': "3 Months ($11)" }
]

const platform_options = [
    { 'value': "", 'name': "Choose Platform" },
    { 'value': "Heurist", 'name': "Heurist" },

]

const gpu_options = [
    { 'value': "", 'name': "Choose GPU" },
    { 'value': "RTX A6000", 'name': "RTX A6000 (LLM + SD Miner)" },

]

const quantity_options = [
    { 'value': "0", 'name': "How many GPUs?" },
    { 'value': "1", 'name': "1" },
    // { 'value': "2", 'name': "2" },
    // { 'value': "4", 'name': "4" },
    // { 'value': "8", 'name': "8" },
]


const PaymentForm = () => {
    const [walletAddress, setWalletAddress] = useState('');
    const [isWalletConnected, setIsWalletConnected] = useState(false);
    const [error, setError] = useState('');
    const [gpuAvailability, setGpuAvailability] = useState(false);

    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState('horizontal');
    const onFormLayoutChange = ({ layout }) => {
        setFormLayout(layout);
    };
    const [duration, setDuration] = useState('');
    const [platform, setPlatform] = useState("");
    const [gpu, setGpu] = useState('');
    const [quantity, setQuantity] = useState('');
    const [deploying, setDeploying] = useState(false); // Track if deployment is in process

    const [isDurationValid, setIsDurationValid] = useState(false);
    const [isPlatformValid, setIsPlatformValid] = useState(false);
    const [isGpuValid, setIsGpuValid] = useState(false);
    const [isQuantityValid, setIsQuantityValid] = useState(false);

    useEffect(() => {
        // Effect to check availability whenever gpu or quantity changes
        const checkFormValidity = () => {
            setIsDurationValid(duration !== "0 Month" && duration !== "");
            setIsPlatformValid(platform !== "");
            setIsGpuValid(gpu !== "");
            setIsQuantityValid(quantity !== "0" && quantity !== "");
          };
          
        checkFormValidity();
          
        const checkAvailability = async () => {
            if (gpu && quantity) {
                try {
                    const response = await fetch('https://api.nirmaan.ai/check-availability', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            ethereum_address: walletAddress,
                            gpu_selection: gpu,
                            quantity: quantity
                        })
                    });
                    const data = await response.json();
                    setGpuAvailability(data.available_gpus > 0);
                    if (!data.available_gpus > 0) {
                        notification.error({
                            message: <span style={{ color: 'white', marginLeft: '60px' }}>GPU Availability</span>,
                            duration: 10,
                        icon: <img src={iconSrc} alt="Icon" style={{
                            height: '47px',
                            width: '50px',
                            margin: '5px 5px 2px 5px' // Reduced bottom margin to move the icon up
                        }}/>,
                        description: (
                            <span style={{ display: 'block', color: 'white', marginLeft: '60px' }}>
                              <a style={{ color: 'white'}}>
                                Selected GPUs are Currently Out of Stock!
                              </a>
                            </span>
                          ),
                        placement: 'topRight',
                        style: {
                            backgroundColor: 'black',
                            border: '1px solid #8444bc',
                            borderRadius: '4px', // Increase for smoother corners
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Soften the shadow
                            overflow: 'hidden' // Ensure that children elements do not overflow the rounded borders
                        },
                        });
                    }
                } catch (error) {
                    console.error('Error checking GPU availability:', error);
                    notification.error({
                        message: <span style={{ color: 'white', marginLeft: '60px' }}>Error</span>,
                        duration: 10,
                        icon: <img src={iconSrc} alt="Icon" style={{
                            height: '47px',
                            width: '50px',
                            margin: '5px 5px 2px 5px' // Reduced bottom margin to move the icon up
                        }}/>,
                        description: (
                            <span style={{ display: 'block', color: 'white', marginLeft: '60px' }}>
                              <a style={{ color: 'white'}}>
                                Failed to Check GPU Availability
                              </a>
                            </span>
                          ),
                        placement: 'topRight',
                        style: {
                            backgroundColor: 'black',
                            border: '1px solid #8444bc',
                            borderRadius: '4px', // Increase for smoother corners
                            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Soften the shadow
                            overflow: 'hidden' // Ensure that children elements do not overflow the rounded borders
                        },
                    });
                }
            }
        };

        checkAvailability();
    }, [duration, platform, gpu, quantity, walletAddress]);


    const switchToArbitrumNetwork = async () => {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0xa4b1' }] // Arbitrum One mainnet chain ID
            });
        } catch (error) {
            if (error.code === 4902) {
                try {
                    // This error code indicates the network has not been added to MetaMask
                    // Add Arbitrum network if it's not added
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainId: '0xa4b1',
                                chainName: 'Arbitrum One',
                                rpcUrls: ['https://arb1.arbitrum.io/rpc'],
                                nativeCurrency: {
                                    name: 'Ether',
                                    symbol: 'ETH',
                                    decimals: 18
                                },
                                blockExplorerUrls: ['https://arbiscan.io']
                            }
                        ]
                    });
                } catch (addError) {
                    setError('Failed to add the Arbitrum network.');
                    console.error('Error adding Arbitrum network:', addError);
                }
            } else {
                setError('Failed to switch to the Arbitrum network.');
                console.error('Error switching to Arbitrum network:', error);
            }
        }
    };


    const connectWalletHandler = async () => {
        if (window.ethereum) {
          try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            if (accounts.length > 0) {
                setWalletAddress(accounts[0]);
                setIsWalletConnected(true);

                await switchToArbitrumNetwork();
            }
          } catch (err) {
            setError(err.message);
          }
        } else {
          setError('Please install MetaMask!');
        }
    };

    const handleSubmit = async () => {
        // Ensure all required fields are selected
        if (!walletAddress || !duration || !platform || !gpu || !quantity) {
          setError("Please fill all fields and connect your wallet.");
          return;
        }
      
        // Parse the duration to get the first number (assuming the format is like "1 Month")
        const selectedDurationNumber = parseInt(duration.split(" ")[0]);
        
        // Call startPayment with the parsed number and gpu
        await startPayment(selectedDurationNumber, gpu);
      };

    const calculateAmount = (durationNumber, selectedGpu) => {
        let price = 0;
      
        if (selectedGpu === "RTX A6000") {
          switch (durationNumber) {
            case 1:
              price = 1; // Price for 1 month
              break;
            case 2:
              price = 7; // Price for 2 months
              break;
            case 3:
              price = 11; // Price for 3 months
              break;
            default:
              price = 0;
          }
        }
        // Add cases for other GPU types if necessary
      
        return ethers.utils.parseUnits(price.toString(), 6);
      };
  
    // Define the payment function
    const startPayment = async () => {
        if (!walletAddress) {
            setError("Please connect your wallet first.");
            return;
        }

        if (deploying || !gpuAvailability) return;
        setDeploying(true);
      
        try {
            const eth_provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = eth_provider.getSigner();
            const usdcContract = new ethers.Contract(
                arb_usdc_address, // Actual USDC contract address for your network
                [
                    "function balanceOf(address account) external view returns (uint256)",
                    "function transfer(address recipient, uint256 amount) external returns (bool)"
                ],
                signer
            );
      
            const amount = calculateAmount(parseInt(duration.split(" ")[0]), gpu);
            const balance = await usdcContract.balanceOf(walletAddress);
      
            if (balance.lt(amount)) {
                setError("Insufficient USDC balance.");
                return;
            }
      
            const tx = await usdcContract.transfer(nirmaan_wallet_address, amount);
            const receipt = await tx.wait(); // Wait for the transaction to be mined
      
            if (receipt.status === 1) {
                console.log("Transaction Successful!");
                const txnHash = tx.hash;
                // setTransactionHash(tx.hash);
                const durationInMonths = parseInt(duration.split(" ")[0]); // Ensuring usage of the variable
      
                // Prepare form data
                const formData = {
                    ethereum_address: walletAddress,
                    duration: durationInMonths,  // Assuming duration is like "1 Month"
                    provider: platform,
                    gpu_selection: gpu,
                    quantity_of_miners: parseInt(quantity),  // Make sure this is an integer
                    amount_paid: parseFloat(ethers.utils.formatUnits(amount, 6)),  // Convert to float if it's not already
                    txn_hash: txnHash // Assuming you have this after a transaction
                };

                console.log(formData)

                notification.success({
                    message: <span style={{ color: 'white', marginLeft: '60px' }}>Deployment Initiated!</span>,
                    duration: 10,
                    icon: <img src={iconSrc} alt="Icon" style={{
                        height: '47px',
                        width: '50px',
                        margin: '5px 5px 2px 5px' // Reduced bottom margin to move the icon up
                    }}/>,
                    description: (
                      <span style={{ display: 'block', color: 'white', marginLeft: '60px' }}>
                        <a href={`https://www.heurist.ai/mining`} target="_blank" rel="noopener noreferrer" style={{ color: '#E0B0FF', textDecoration: 'underline' }}>
                          Track your Miner Here In 1 Hour
                        </a>
                      </span>
                    ),
                    style: {
                        backgroundColor: 'black',
                        border: '1px solid #8444bc',
                        borderRadius: '4px', // Increase for smoother corners
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Soften the shadow
                        overflow: 'hidden' // Ensure that children elements do not overflow the rounded borders
                    },
                    onClose: () => {
                      // Reload the page
                      window.location.reload();
                    }
                });
                

                // Send form data to your backend
                const response = await fetch("https://api.nirmaan.ai/submit", {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                });
      
                if (!response.ok) {
                    const errorData = await response.json();
                    setError(`Error: ${errorData.detail}`);
                    return;
                }
      
                const responseData = await response.json();
                console.log("Deployed!", responseData); // Handle or display the response data as needed
            } else {
                throw new Error("Transaction failed with status " + receipt.status);
            }
        } catch (err) {
            console.error("Transaction error: ", err);
            setError(err.message);
        } finally {
            setDeploying(false); // Re-enable the deploy button after transaction
        }
    };

    const buttonItemLayout =
        formLayout === 'horizontal'
            ? {
                wrapperCol: {
                    span: 14,
                    offset: 4,
                },
            }
            : null;

    return (
        <div style={{
            padding: '24px',
            background: '#1C1B1E',
            borderRadius: '4px',
            border: '1px solid grey',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            margin: '16px',
        }}>
            <Form
                layout="vertical"
                form={form}
                initialValues={{
                    duration: "0 Month",
                    platform: "",
                    gpu: "",
                    quantity: "0"
                }}
                style={{ maxWidth: '600px' }}
            >
                <Form.Item
                    id="wallet-address"
                    // label="1. Set your EVM address"
                    label={<span style={{ color: 'white' }}>1. Set your EVM address</span>}
                >
                    <Space.Compact
                        style={{
                            width: '100%',
                        }}
                    >
                        <Input
                            style={{ backgroundColor: '#1C1B1E', borderColor: '#B866F6', color: '#fff', borderWidth: 2 }}
                            value={walletAddress || 'Connect wallet to enter address'} 
                            disabled 
                        />
                        {!isWalletConnected && (
                        <Button type="primary" 
                        onClick={connectWalletHandler}
                        className='connectWalletButton'
                        >
                            Connect Wallet</Button>
                        )}
                        {isWalletConnected && (
                        <Button disabled>Wallet Connected</Button>
                        )}
                    </Space.Compact>
                    {error && <p className="error-message">{error}</p>}
                </Form.Item>
                <Form.Item
                    name="duration"
                    label={<span style={{ color: 'white' }}>2. Set your duration</span>}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        style={{ width: '100%', backgroundColor: '#1C1B1E', borderColor: '#B866F6', color: '#fff', borderWidth: 2 }}
                        placeholder="Set your duration"
                        // onChange={onGenderChange}
                        onChange={(value) => {
                        setDuration(value);
                        setIsDurationValid(value !== "0 Month" && value !== "");
                         }}
                        allowClear

                    >
                        {duration_options.map(a => <Option style={{ background: "black" }} value={a.value}>{a.name}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="platform"
                    label={<span style={{ color: 'white' }}>3. Choose Platform</span>}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        className="platform-select"
                        style={{ width: '100%', backgroundColor: '#1C1B1E', borderColor: '#B866F6', color: '#fff', borderWidth: 2 }}
                        placeholder="Choose platform"
                        onChange={(value) => {
                        setPlatform(value);
                        setIsPlatformValid(value !== "");
                        }}
                        allowClear
                    >
                        {platform_options.map(a => <Option style={{ background: "black" }} value={a.value}>{a.name}</Option>)}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="gpu"
                    label={<span style={{ color: 'white' }}>4. Choose your GPU</span>}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        placeholder="Choose GPU"
                        onChange={(value) => {
                        setGpu(value);
                        setIsGpuValid(value !== "");
                        }}
                        allowClear
                    >
                        {gpu_options.map(a => <Option style={{ background: "black" }} value={a.value}>{a.name}</Option>)}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="quantity"
                    label={<span style={{ color: 'white' }}>5. Choose Quantity</span>}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        placeholder="How many GPUs?"
                        onChange={(value) => {
                        setQuantity(value);
                        setIsQuantityValid(value !== "0" && value !== "");
                        }}
                        allowClear
                    >
                        {quantity_options.map(a => <Option style={{ background: "black" }} value={a.value}>{a.name}</Option>)}
                    </Select>
                </Form.Item>

                <Form.Item {...buttonItemLayout}>
                    <Button 
                    type="primary" 
                    onClick={handleSubmit}
                    className='deployMinerButton'
                    disabled={!isDurationValid || !isPlatformValid || !isGpuValid || !isQuantityValid || !gpuAvailability || deploying}
                    >
                        {deploying ? <><SyncOutlined style={{ marginRight:'3px' }} spin /> Deploying...</> : 'Deploy Miner'}
                    </Button>
                </Form.Item>
            </Form>
        </div >
    );
};
export default PaymentForm;